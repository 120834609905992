import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    //console.log("connected");
    // Called when there's incoming data on the websocket for this channel
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    //console.log("dsic");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $('#message_counter').removeClass("shake")

    if(data.message_counter > 0) {
      $('#message_counter').removeClass('d-none').text(data.message_counter);
      new_message_sound_play();
      $('#message_counter').addClass("shake")
    } else {
      $('#message_counter').addClass('d-none');
    }

    if($('#list_'+data.message.id).length > 0) {
      var badge = $('#list_'+data.message.id).find('.badge-primary')
      var count = parseInt(badge.text()) + 1;
      badge.text(count);
      badge.attr('data-unreadcounter', count);
    }

    if($('#content_'+data.message.id).length > 0) {
      $('#content_'+data.message.id).append(data.message.body);
      scrollToBottom();
    }
  }
});
