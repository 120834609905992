import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
window.Rails = Rails;
window.jQuery = $;
window.$ = $;

import "popper.js"
import "bootstrap"

Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

import "nested_form"
import "owl.carousel.min"

import "bootstrap-select"

import "dropzone"

window.new_message_sound_play = function() {
  var audio = new Audio('/hey.mp3');
  audio.play();
}


$(function() {
  $.fn.selectpicker.defaults = {
    noneSelectedText: 'Bitte wählen...',
    noneResultsText: 'Keine Ergebnisse für {0}',
    countSelectedText: function (numSelected, numTotal) {
      return (numSelected == 1) ? '{0} Element ausgewählt' : '{0} Elemente ausgewählt';
    },
    maxOptionsText: function (numAll, numGroup) {
      return [
        (numAll == 1) ? 'Limit erreicht ({n} Element max.)' : 'Limit erreicht ({n} Elemente max.)',
        (numGroup == 1) ? 'Gruppen-Limit erreicht ({n} Element max.)' : 'Gruppen-Limit erreicht ({n} Elemente max.)'
      ];
    },
    selectAllText: 'Alles auswählen',
    deselectAllText: 'Nichts auswählen',
    multipleSeparator: ', '
  };

    if($('#project-photos').length !== 0) {
    $('#project-photos').owlCarousel({
      autoplay: false,
      autoplayTimeout: 4000,
      loop: true,
      items: 1,
      center: true,
      nav: true,
      thumbs: true,
      thumbImage: false,
      thumbsPrerendered: true,
      thumbContainerClass: 'owl-thumbs',
      thumbItemClass: 'owl-thumb-item',
      navText: ["<i class='ti-angle-left icon-nav'></i>","<i class='ti-angle-right icon-nav'></i>"],
    });
  }

  if($('.brand-slider').length !== 0) {
    $('.brand-slider').owlCarousel({
       loop:true,
       margin:15,
       nav:false,
       autoplay:false,
       dots:false,
       items:5,
       responsive:{
           0:{
               items:2,
           },
           600:{
               items:3,
           },
           1200:{
               items:5,
           }
       }
    });
  }
})

require("trix")
require("@rails/actiontext")
